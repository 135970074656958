exports.components = {
  "component---cache-caches-gatsby-plugin-offline-next-app-shell-js": () => import("./../../caches/gatsby-plugin-offline-next/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-next-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-ui-kit-buttons-js": () => import("./../../../src/pages/UI-KIT/buttons.js" /* webpackChunkName: "component---src-pages-ui-kit-buttons-js" */),
  "component---src-pages-ui-kit-colours-images-js": () => import("./../../../src/pages/UI-KIT/Colours-Images.js" /* webpackChunkName: "component---src-pages-ui-kit-colours-images-js" */),
  "component---src-pages-ui-kit-typography-js": () => import("./../../../src/pages/UI-KIT/typography.js" /* webpackChunkName: "component---src-pages-ui-kit-typography-js" */),
  "component---src-pages-ui-kit-ui-icons-js": () => import("./../../../src/pages/UI-KIT/UI-Icons.js" /* webpackChunkName: "component---src-pages-ui-kit-ui-icons-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-page-about-us-js": () => import("./../../../src/templates/page-about-us.js" /* webpackChunkName: "component---src-templates-page-about-us-js" */),
  "component---src-templates-page-author-js": () => import("./../../../src/templates/page-author.js" /* webpackChunkName: "component---src-templates-page-author-js" */),
  "component---src-templates-page-beliefs-and-values-js": () => import("./../../../src/templates/page-beliefs-and-values.js" /* webpackChunkName: "component---src-templates-page-beliefs-and-values-js" */),
  "component---src-templates-page-blog-js": () => import("./../../../src/templates/page-blog.js" /* webpackChunkName: "component---src-templates-page-blog-js" */),
  "component---src-templates-page-blog-post-js": () => import("./../../../src/templates/page-blog-post.js" /* webpackChunkName: "component---src-templates-page-blog-post-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page-contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-devotionals-js": () => import("./../../../src/templates/page-devotionals.js" /* webpackChunkName: "component---src-templates-page-devotionals-js" */),
  "component---src-templates-page-donations-js": () => import("./../../../src/templates/page-donations.js" /* webpackChunkName: "component---src-templates-page-donations-js" */),
  "component---src-templates-page-event-js": () => import("./../../../src/templates/page-event.js" /* webpackChunkName: "component---src-templates-page-event-js" */),
  "component---src-templates-page-events-js": () => import("./../../../src/templates/page-events.js" /* webpackChunkName: "component---src-templates-page-events-js" */),
  "component---src-templates-page-faqs-js": () => import("./../../../src/templates/page-faqs.js" /* webpackChunkName: "component---src-templates-page-faqs-js" */),
  "component---src-templates-page-groups-js": () => import("./../../../src/templates/page-groups.js" /* webpackChunkName: "component---src-templates-page-groups-js" */),
  "component---src-templates-page-here-for-you-js": () => import("./../../../src/templates/page-here-for-you.js" /* webpackChunkName: "component---src-templates-page-here-for-you-js" */),
  "component---src-templates-page-history-js": () => import("./../../../src/templates/page-history.js" /* webpackChunkName: "component---src-templates-page-history-js" */),
  "component---src-templates-page-image-gallery-js": () => import("./../../../src/templates/page-image-gallery.js" /* webpackChunkName: "component---src-templates-page-image-gallery-js" */),
  "component---src-templates-page-jobs-js": () => import("./../../../src/templates/page-jobs.js" /* webpackChunkName: "component---src-templates-page-jobs-js" */),
  "component---src-templates-page-leadership-js": () => import("./../../../src/templates/page-leadership.js" /* webpackChunkName: "component---src-templates-page-leadership-js" */),
  "component---src-templates-page-lesson-js": () => import("./../../../src/templates/page-lesson.js" /* webpackChunkName: "component---src-templates-page-lesson-js" */),
  "component---src-templates-page-ministry-js": () => import("./../../../src/templates/page-ministry.js" /* webpackChunkName: "component---src-templates-page-ministry-js" */),
  "component---src-templates-page-photo-gallery-js": () => import("./../../../src/templates/page-photo-gallery.js" /* webpackChunkName: "component---src-templates-page-photo-gallery-js" */),
  "component---src-templates-page-press-kit-js": () => import("./../../../src/templates/page-press-kit.js" /* webpackChunkName: "component---src-templates-page-press-kit-js" */),
  "component---src-templates-page-private-lesson-js": () => import("./../../../src/templates/page-private-lesson.js" /* webpackChunkName: "component---src-templates-page-private-lesson-js" */),
  "component---src-templates-page-private-school-js": () => import("./../../../src/templates/page-private-school.js" /* webpackChunkName: "component---src-templates-page-private-school-js" */),
  "component---src-templates-page-school-js": () => import("./../../../src/templates/page-school.js" /* webpackChunkName: "component---src-templates-page-school-js" */),
  "component---src-templates-page-services-js": () => import("./../../../src/templates/page-services.js" /* webpackChunkName: "component---src-templates-page-services-js" */),
  "component---src-templates-page-simple-js": () => import("./../../../src/templates/page-simple.js" /* webpackChunkName: "component---src-templates-page-simple-js" */),
  "component---src-templates-page-subministry-js": () => import("./../../../src/templates/page-subministry.js" /* webpackChunkName: "component---src-templates-page-subministry-js" */),
  "component---src-templates-page-taxonomy-campanya-js": () => import("./../../../src/templates/page-taxonomy-campanya.js" /* webpackChunkName: "component---src-templates-page-taxonomy-campanya-js" */),
  "component---src-templates-page-taxonomy-js": () => import("./../../../src/templates/page-taxonomy.js" /* webpackChunkName: "component---src-templates-page-taxonomy-js" */),
  "component---src-templates-page-taxonomy-tag-js": () => import("./../../../src/templates/page-taxonomy-tag.js" /* webpackChunkName: "component---src-templates-page-taxonomy-tag-js" */),
  "component---src-templates-page-testimonies-js": () => import("./../../../src/templates/page-testimonies.js" /* webpackChunkName: "component---src-templates-page-testimonies-js" */),
  "component---src-templates-page-testimony-js": () => import("./../../../src/templates/page-testimony.js" /* webpackChunkName: "component---src-templates-page-testimony-js" */),
  "component---src-templates-page-welcome-js": () => import("./../../../src/templates/page-welcome.js" /* webpackChunkName: "component---src-templates-page-welcome-js" */),
  "component---src-templates-page-worship-js": () => import("./../../../src/templates/page-worship.js" /* webpackChunkName: "component---src-templates-page-worship-js" */),
  "component---src-templates-pages-js": () => import("./../../../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */)
}

