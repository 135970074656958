// gatsby-browser.js
import React from 'react';
import { showLoader, hideLoader } from './src/utils/loader';
import { AuthProvider } from './src/context/AuthContext';
import LanguageContextProvider from './src/context';
import { SSRProvider } from '@react-aria/ssr';
import "@popperjs/core/dist/umd/popper.min";
import "bootstrap/dist/css/bootstrap.min.css";
import './src/assets/css/index.scss';
import { Helmet } from 'react-helmet';

// Define your private paths
const privatePaths = ["/login", "/private-school-page", "/private-lesson-page", "/escola-biblica-online"];

// Handle route changes to show and hide the loader
export const onPreRouteUpdate = () => {
  showLoader();
};

export const onRouteUpdate = () => {
  hideLoader();
};

// Conditionally import Bootstrap JS only in the browser
if (typeof window !== "undefined") {
  import("bootstrap/dist/js/bootstrap.min");
}

// Unified wrapRootElement function for both gatsby-browser.js and gatsby-ssr.js
export const wrapRootElement = ({ element }) => (
  <SSRProvider>
    <LanguageContextProvider langcode="ca">
      <AuthProvider>
        {element}
      </AuthProvider>
    </LanguageContextProvider>
  </SSRProvider>
);
// Add the wrapPageElement function for meta tags
export const wrapPageElement = ({ element, props }) => {
  const isPrivatePath = privatePaths.includes(props.location.pathname);

  return (
    <>
      {isPrivatePath && (
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      )}
      {element}
    </>
  );
};
