import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { navigate } from "gatsby";
import { LanguageContext } from "./index"; // Import your LanguageContext for translations
import { showLoader, hideLoader } from "../utils/loader"; // Import the loader functions

// Create the Auth context
export const AuthContext = createContext();

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.GATSBY_DRUPAL_API_URL,
  withCredentials: true, // Include cookies in all requests
  headers: {
    "Content-Type": "application/json",
  },
});

// Check if code is running in the browser
const isBrowser = typeof window !== "undefined";

// Define private paths
const privatePaths = ["/private-school-page", "/private-lesson-page", "/escola-biblica-online"];

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state for async operations
  const languageContext = useContext(LanguageContext);
  const { t } = languageContext || { t: (text) => text }; // Fallback for translation

  // Function to check login status from the server
  const checkLoginStatus = async () => {
    setLoading(true);
    showLoader(); // Show the loader when checking status
    try {
      const response = await axiosInstance.get('/jwt-cookie-auth/check-status');
      
      if (response.status === 200 && response.data.status === "authorized") {
        setUser({ authenticated: true }); // Set user object indicating they are authenticated
      } else {
        setUser(null); // If not authorized, set user to null

        // Check if the current path is a private path
        if (isBrowser && privatePaths.includes(window.location.pathname)) {
          navigate("/login"); // Redirect to login page if accessing a private page
        }
      }
    } catch (error) {
      setUser(null); // Set user to null on error

      // Redirect only if the current path is private
      if (isBrowser && privatePaths.includes(window.location.pathname)) {
        navigate("/login"); // Redirect to login page on error
      }
    } finally {
      setLoading(false);
      hideLoader(); // Hide the loader once the status check is complete
    }
  };

  // Function to refresh the access token (if needed)
  const refreshToken = async () => {
    showLoader(); // Show loader during token refresh process
    try {
      const response = await axiosInstance.post(
        '/oauth/token',
        {
          grant_type: "refresh_token",
          client_id: process.env.GATSBY_DRUPAL_CLIENT_ID,
          client_secret: process.env.GATSBY_DRUPAL_CLIENT_SECRET,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status === 200) {
        setUser(response.data.user || null); // Set user information or null if no user
      } else {
        logout(); // If refresh fails, log out the user
      }
    } catch (error) {
      //console.error("Error during token refresh:", error);
      logout(); // Log out on error
    } finally {
      hideLoader(); // Hide the loader after token refresh attempt completes
    }
  };

  // Logout function to clear the user's session
  const logout = async () => {
    showLoader(); // Show loader during logout process
    try {
      await axiosInstance.post('/jwt-cookie-auth/logout');
      setUser(null);
      if (isBrowser) {
        navigate("/login");
      }
    } catch (error) {
      //console.error("Error during logout:", error);
    } finally {
      hideLoader(); // Hide the loader after logout process completes
    }
  };

    // Function to handle login (send credentials to the backend)
    const login = async (username, password) => {
      setLoading(true);
      showLoader(); // Show the loader when logging in
      try {
        const response = await axiosInstance.post('/jwt-cookie-auth/login', {
          username,
          password,
        });
    
        if (response.status === 200 && response.data.message === "Logged in successfully.") {
          setUser({ authenticated: true });
          if (isBrowser) {
            navigate("/escola-biblica-online");
          }
        } else {
          setUser(null);
          throw new Error("Invalid username or password");
        }
      } catch (error) {
        // Handle specific server error response for incorrect credentials
        if (error.response && error.response.status === 400) {
          const errorData = error.response.data;
          if (errorData?.error_description && errorData.error_description === "The user credentials were incorrect.") {
            setUser(null);
            throw new Error("Invalid username or password");
          } else if (errorData?.error_description) {
            // If other error descriptions are present, show a generic message
            throw new Error(errorData.error_description);
          }
        }
    
        // For other errors not covered by the specific case above
        console.error("Login error:", error);
        throw new Error("An unexpected error occurred. Please try again.");
      } finally {
        setLoading(false);
        hideLoader(); // Hide the loader after login attempt completes
      }
    };
  
  

  // Check login status when the component mounts (Run only once)
  useEffect(() => {
    if (isBrowser) {
      checkLoginStatus(); // This will only run once on mount

      // Set up an interval to refresh the token periodically (e.g., every 15 minutes)
     // const interval = setInterval(refreshToken, 15 * 60 * 1000);
      
      // Cleanup interval on component unmount
     // return () => clearInterval(interval);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
